<template>
    <div class='user-widget'>
        <div class='user-widget__info'>
            <img :src='user.picture' class='user-widget__image rounded-circle' :alt='user.name'>
            <label class='user-widget__name'>
                {{ user.name }}
            </label>
        </div>
        <button @click='logout()' class='user-widget__logout btn btn-light'>Logout <i
            class='fas fa-logout'></i></button>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    computed: {
        ...mapState('auth', [
            'user',
        ]),
    },

    methods: {
        ...mapActions('auth', [
            'logout',
        ]),
    },
};
</script>

<style scoped>
.user-widget__info {
    display: flex;
    align-items: center;
}

.user-widget__image {
    height: 32px;
}

.user-widget__name {
    margin-left: 6px;
}

.user-widget__logout {
    margin-top: 6px;
}
</style>
