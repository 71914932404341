<template>
    <div class='main'>
        <div class='sidebar'>
            <div class='sidebar__header'>
                <a href='/'
                   class='d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none'>
                    <span class='fs-4'>TimeTracker</span>
                </a>
            </div>
            <div class='sidebar__divider sidebar__divider_padding'></div>
            <div class='sidebar__content'>
                <div class='sidebar__menu'>
                    <ul class='nav nav-pills flex-column mb-auto'>
                        <li>
                            <router-link active-class='active' class='nav-link link-dark'
                                         to='/worklogs'>Worklogs
                            </router-link>
                        </li>
                        <li>
                            <router-link active-class='active' class='nav-link link-dark'
                                         to='/projects'>Projects
                            </router-link>
                        </li>
                        <li>
                            <router-link active-class='active' class='nav-link link-dark'
                                         to='/users'>Users
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class='sidebar__footer'>
                <div class='sidebar__user-widget'>
                    <UserWidget />
                </div>
            </div>
        </div>
        <div class='content'>
            <router-view />
        </div>
    </div>
</template>

<style>

.content {
    display: flex;
    flex: 1;
    overflow: auto;
}

.sidebar {
    display: flex;
    flex-flow: column;
    border-right: 1px solid #ddd;
}

.sidebar__divider {
    border-bottom: 1px solid #ddd;
    margin: 1rem 0;
}

.sidebar__divider_padding {
    margin-right: 1rem;
    margin-left: 1rem;
}

.sidebar__header {
    padding: 1rem 1rem 0 1rem;
}

.sidebar__content {
    overflow-y: auto;
    overflow-x: hidden;
}

.sidebar__menu {
    display: flex;
    flex-flow: column;
    padding: 0 1rem;
    min-width: 200px;
}

.sidebar__footer {
    margin-top: auto;
}

.sidebar__user-widget {
    padding: 1rem;
}

</style>
<script>
import UserWidget from '../auth/UserWidget';

export default {
    components: { UserWidget },
};
</script>
